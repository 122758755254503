@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;800&display=swap");
@import "./variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

$purple: #00002a;

body {
  background: rgb(0, 0, 0);
  background: linear-gradient(transparent, #000 50%),
    linear-gradient(45deg, #00002a 75%, #9d2069 100%);
}

.site-header {
  position: relative;
  background-image: url(../images/planet-bg.png);
  background-repeat: no-repeat;
  background-position: 50% 95%;

  .space-man {
    @include media-breakpoint-down(lg) {
      position: relative;
      top: -3rem;
    }
  }

  .container > img:first-child {
    top: -3.4rem;
    left: 50%;
    margin-left: -50%;
  }

  .site-header__content {
    position: relative;
    z-index: 10;
  }

  .entrepot-button {
    padding: 1rem 3rem;
    box-shadow: 0 8px 0 2px #56d2b8;
    border-radius: 50px;
    border: 4px solid #56d2b8;
    background-color: #010113;
    transition: all 0.3s;

    &:active {
      box-shadow: 0 4px #56d2b8;
      transform: translateY(4px);
    }
  }
}
@include media-breakpoint-down(sm) {
  .site-header {
    background-position: 50% 100%;
    .entrepot-button {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.btn-img {
  background: none;
  border: none;
  outline: none;
}

.decoration {
  position: absolute;
  left: 6rem;
  top: -10px;
  overflow: visible;
  width: 53px;
  height: auto;
  background: #000;
  padding-left: 10px;
  z-index: 1;
  color: #fff;

  @include media-breakpoint-down(lg) {
    left: 3rem;
  }
}

.decoration--right {
  right: 6rem;
  left: auto;
  transform: rotate(180deg);
  @include media-breakpoint-down(lg) {
    right: 3rem;
  }
}

.main-content {
  border: 1px solid #ffffff;
  padding: 100px;
  padding-top: 0;
  background: $purple;
  background: #000;
  position: relative;

  h2 {
    letter-spacing: 1rem;
    padding: 0 2rem;
    margin-top: -1rem;
    margin-bottom: 70px;
    background: #000;
  }

  .planet-and-moon {
    color: #fff;
    position: absolute;
    left: 50%;
    width: 140px;
    height: auto;
    bottom: -12px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #000;
    padding: 0 30px;
    overflow: visible;
    -webkit-transition: color 3s 0.7s cubic-bezier(0.28, 0.16, 0.42, 0.99);
    -o-transition: color 3s 0.7s cubic-bezier(0.28, 0.16, 0.42, 0.99);
    transition: color 3s 0.7s cubic-bezier(0.28, 0.16, 0.42, 0.99);
  }
  @include media-breakpoint-down(md) {
    padding: 2rem;

    h2 {
      margin-bottom: 2rem;
      margin-top: 0;
      padding: 0;
    }
  }
}

.planet-and-moon .moon--right {
  -webkit-animation: moonright 2s linear infinite forwards;
  animation: moonright 2s linear infinite forwards;
}

@-webkit-keyframes moonright {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  5% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }

  16.66% {
    -webkit-transform: translate(-7px, -3px);
    transform: translate(-7px, -3px);
  }

  33.33% {
    -webkit-transform: translate(-18px, -6px);
    transform: translate(-18px, -6px);
  }

  50% {
    -webkit-transform: translate(-32.5px, -7px);
    transform: translate(-32.5px, -7px);
  }

  66.66% {
    -webkit-transform: translate(-47px, -6px);
    transform: translate(-47px, -6px);
  }

  83.33% {
    -webkit-transform: translate(-58px, -3px);
    transform: translate(-58px, -3px);
  }

  95% {
    -webkit-transform: translate(-64px, -1px);
    transform: translate(-64px, -1px);
  }

  100% {
    -webkit-transform: translate(-65px, 0px);
    transform: translate(-65px, 0px);
  }
}

@keyframes moonright {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  5% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }

  16.66% {
    -webkit-transform: translate(-7px, -3px);
    transform: translate(-7px, -3px);
  }

  33.33% {
    -webkit-transform: translate(-18px, -6px);
    transform: translate(-18px, -6px);
  }

  50% {
    -webkit-transform: translate(-32.5px, -7px);
    transform: translate(-32.5px, -7px);
  }

  66.66% {
    -webkit-transform: translate(-47px, -6px);
    transform: translate(-47px, -6px);
  }

  83.33% {
    -webkit-transform: translate(-58px, -3px);
    transform: translate(-58px, -3px);
  }

  95% {
    -webkit-transform: translate(-64px, -1px);
    transform: translate(-64px, -1px);
  }

  100% {
    -webkit-transform: translate(-65px, 0px);
    transform: translate(-65px, 0px);
  }
}

.planet-and-moon .moon--left {
  -webkit-animation: moonleft 2s linear infinite;
  animation: moonleft 2s linear infinite;
}

@-webkit-keyframes moonleft {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  5% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }

  16.66% {
    -webkit-transform: translate(7px, 3px);
    transform: translate(7px, 3px);
  }

  33.33% {
    -webkit-transform: translate(18px, 6px);
    transform: translate(18px, 6px);
  }

  50% {
    -webkit-transform: translate(32.5px, 7px);
    transform: translate(32.5px, 7px);
  }

  66.66% {
    -webkit-transform: translate(47px, 6px);
    transform: translate(47px, 6px);
  }

  83.33% {
    -webkit-transform: translate(58px, 3px);
    transform: translate(58px, 3px);
  }

  95% {
    -webkit-transform: translate(64px, 1px);
    transform: translate(64px, 1px);
  }

  100% {
    -webkit-transform: translate(65px, 0px);
    transform: translate(65px, 0px);
  }
}

@keyframes moonleft {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  5% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }

  16.66% {
    -webkit-transform: translate(7px, 3px);
    transform: translate(7px, 3px);
  }

  33.33% {
    -webkit-transform: translate(18px, 6px);
    transform: translate(18px, 6px);
  }

  50% {
    -webkit-transform: translate(32.5px, 7px);
    transform: translate(32.5px, 7px);
  }

  66.66% {
    -webkit-transform: translate(47px, 6px);
    transform: translate(47px, 6px);
  }

  83.33% {
    -webkit-transform: translate(58px, 3px);
    transform: translate(58px, 3px);
  }

  95% {
    -webkit-transform: translate(64px, 1px);
    transform: translate(64px, 1px);
  }

  100% {
    -webkit-transform: translate(65px, 0px);
    transform: translate(65px, 0px);
  }
}

.site-footer {
  h6 {
    letter-spacing: 4px;
  }
}

.gradient-heading {
  background-image: linear-gradient(to top, #4f0699 0%, #ff0267 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff0267;
  letter-spacing: 1rem;
  font-weight: bold;
}

.music-btn {
  width: 65px;
  height: 65px;
  border: 3px solid #56d2b8;
  border-radius: 50%;
  display: flex;
  position: relative;
  z-index: 20;

  img {
    margin: auto;

    &:first-child {
      display: none;
    }
  }
}

.music-btn--playing {
  img {
    &:first-child {
      display: block;
    }

    &:last-child {
      display: none;
    }
  }
}

.planets-image {
  position: absolute;
  right: -6rem;
  top: 50%;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.floating-element {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, 0px);
  }
}

// n is number of stars required
@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(2000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value} , #{random(2000)}px #{random(2000)}px #FFF";
  }

  @return unquote($value);
}
$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}
#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}
#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
} ;
